import { GiftBrandListingData } from "~/components/gifting/brand-collection-landing/cards_list";
import LocalStorageUtil from "~/utils/local_storage";
import { Header } from "~/types";
import { generateDeviceVerificationToken } from "~/utils/common";
import { clearCookie, getCookie, setCookie } from "~/utils/client_cookie";
import { GetGiftBoxV3Response } from "~/server/types/gift";
import { CoinsSummaryResponse } from "~/server/types/order";
import { clientRepo } from "~/server/apis/client_repo";
import { APIError } from "~/utils/fetch";
import { config } from "~/data/config";

export const gift_v3cache_duration_in_minutes =
  config.sesssionDurationInMinutes.gift_receiver;

export type GiftBoxV3LandingPageData = {
  coinsCount: number;
  brands: GiftBrandListingData[];
};

export type V3GiftBoxRouteData = {
  giftBoxMetadta: GetGiftBoxV3Response;
  coinsCount?: number;
  sessionId: string | null;
  isRtu: boolean;
};

export type GiftingLocalStorageKey =
  | "sessionId"
  | "brands"
  | "getGiftV3"
  | "clickedUnwrap"
  | "showTooltip"
  | "isSmartGC"
  | "base";

export function getV3GiftingDataStorageKey(
  giftingKey: string,
  key: GiftingLocalStorageKey
) {
  let base = `${giftingKey}_gift_v3`;
  switch (key) {
    case "sessionId":
      return `${base}_session_id`;
    case "brands":
      return `${base}_brands`;
    case "getGiftV3":
      return `${base}_get_gift_v3`;
    case "clickedUnwrap":
      return `${base}_clicked_unwrap`;
    case "isSmartGC":
      return `${base}_is_smart_gc`;
    case "showTooltip":
      return `${base}_show_tooltip`;
    case "base":
      return base;
  }
}

export const getV3GiftBoxRouteData = async (
  giftingKey: string
): Promise<V3GiftBoxRouteData> => {
  let deviceVerificationToken: string | undefined = undefined;

  let sessionId = LocalStorageUtil.getItem<string>(
    getV3GiftingDataStorageKey(giftingKey, "sessionId")
  );

  let cookieSessionId = getCookie(
    getV3GiftingDataStorageKey(giftingKey, "sessionId")
  );

  let giftBoxMetadata = LocalStorageUtil.getItem<string>(
    getV3GiftingDataStorageKey(giftingKey, "getGiftV3")
  );

  if (sessionId && giftBoxMetadata && cookieSessionId === sessionId) {
    let coinsResponse: CoinsSummaryResponse | undefined = undefined;
    try {
      coinsResponse = await clientRepo.getCoinsSummary({
        [Header.SessionId]: sessionId,
      });
    } catch (e) {
      // clear the session id and gift box metadata
      LocalStorageUtil.removeItem(
        getV3GiftingDataStorageKey(giftingKey, "sessionId")
      );
      LocalStorageUtil.removeItem(
        getV3GiftingDataStorageKey(giftingKey, "getGiftV3")
      );
      clearCookie(getV3GiftingDataStorageKey(giftingKey, "sessionId"));
      window.location.href = "/gift-box/" + giftingKey;
      throw e;
    }
    return {
      coinsCount: coinsResponse?.totalAvailable,
      giftBoxMetadta: JSON.parse(giftBoxMetadata),
      sessionId: sessionId,
      isRtu: coinsResponse.totalConsumed > 0,
    };
  } else {
    try {
      deviceVerificationToken = await generateDeviceVerificationToken();
    } catch (error) {
      console.log("Error getting device verification token", error);
    }

    try {
      let giftBoxMetadta = await clientRepo.getGiftBoxV3(giftingKey, {
        [Header.FpDeviceToken]: deviceVerificationToken ?? "",
        [Header.SessionId]: "",
      });
      let coinsCount: number | undefined = undefined;
      let isRtu = false;

      sessionId = giftBoxMetadta.sessionId;
      if (sessionId) {
        LocalStorageUtil.setItem(
          getV3GiftingDataStorageKey(giftingKey, "getGiftV3"),
          JSON.stringify(giftBoxMetadta),
          gift_v3cache_duration_in_minutes
        );

        storeV3GiftSessionId({
          giftingKey: giftingKey,
          sessionId: sessionId,
        });

        let coinsSummary = await clientRepo.getCoinsSummary({
          [Header.SessionId]: sessionId,
        });
        coinsCount = coinsSummary.totalAvailable;
        isRtu = coinsSummary.totalConsumed > 0;
      }
      return {
        giftBoxMetadta: giftBoxMetadta,
        coinsCount: coinsCount,
        sessionId: sessionId,
        isRtu: isRtu,
      };
    } catch (error) {
      if (error instanceof APIError && error.code === 401) {
        //clear
        LocalStorageUtil.removeItem(
          getV3GiftingDataStorageKey(giftingKey, "sessionId")
        );
        LocalStorageUtil.removeItem(
          getV3GiftingDataStorageKey(giftingKey, "getGiftV3")
        );
        clearCookie(getV3GiftingDataStorageKey(giftingKey, "sessionId"));

        window.location.href = "/gift-box/" + giftingKey;
      }
      throw error;
    }
  }
};

export function storeV3GiftSessionId(props: {
  sessionId: string;
  giftingKey: string;
}) {
  LocalStorageUtil.setItem(
    getV3GiftingDataStorageKey(props.giftingKey, "sessionId"),
    props.sessionId,
    gift_v3cache_duration_in_minutes
  );

  setCookie({
    key: getV3GiftingDataStorageKey(props.giftingKey, "sessionId"),
    value: props.sessionId,
    expiryInMinutes: gift_v3cache_duration_in_minutes,
  });
}

export function getMultibrandHomeBrandListFromLocalStorage(
  giftingKey: string
): GiftBrandListingData[] | undefined {
  let brands = LocalStorageUtil.getItem<string>(
    getV3GiftingDataStorageKey(giftingKey, "brands")
  );
  if (brands) {
    return JSON.parse(brands) as GiftBrandListingData[];
  }
}
